import PriceKnowledgeBaseDemo from "../../../components/ai/Demos/PriceKnowledgeBaseTest";


export const PriceKnowledgeBase = () => {
  return (
    <div>
      <div className="flex-1 p-6 bg-white rounded-lg shadow-md">
          <h3 className="text-xl font-semibold mb-4">Price Knowledge Base</h3>
          <PriceKnowledgeBaseDemo/>
        </div>
    </div>
  );
}
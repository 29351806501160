import React, { useState, useEffect } from "react";
import {
  CategoriesService,
  getQuestionsKnowledge,
  upsertQuestionsKnowledge,
  uploadQuestionPinecone,
} from "../../../services";

function timeAgo(inputDateTime: string): string {
  const date = new Date(inputDateTime).getTime();

  const now = new Date(
    Date.UTC(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate(),
      new Date().getUTCHours(),
      new Date().getUTCMinutes(),
      new Date().getUTCSeconds(),
      new Date().getUTCMilliseconds()
    )
  );

  const diffInSeconds = Math.floor((now.getTime() - date) / 1000);

  if (diffInSeconds < 0) return "in the future";
  if (diffInSeconds < 60) return `${diffInSeconds} second(s) ago`;

  const diffInMinutes = Math.floor(diffInSeconds / 60);
  if (diffInMinutes < 60) return `${diffInMinutes} minute(s) ago`;

  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) return `${diffInHours} hour(s) ago`;

  const diffInDays = Math.floor(diffInHours / 24);
  return `${diffInDays} day(s) ago`;
}

const QuestionKnowledgeBase: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [questions, setQuestions] = useState<any[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [rowsToCommit, setRowsToCommit] = useState<number>(0);
  const [activeCategories, setActiveCategories] = useState<
    { id: string; name: string }[]
  >([]);
  const [filterCategory, setFilterCategory] = useState<string>("");
  const [filterToggle, setFilterToggle] = useState<boolean>(false);
  const [newRow, setNewRow] = useState<any | null>(null);
  const [editRowId, setEditRowId] = useState<string | null>(null);
  const [originalRowData, setOriginalRowData] = useState<any | null>(null);
  const [modal, setModal] = useState<{
    show: boolean;
    action: "delete" | "undo";
    rowId: string | null;
  }>({ show: false, action: "delete", rowId: null });
  const [message, setMessage] = useState<{
    type: "success" | "error";
    text: string;
  } | null>(null);

  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    fetchActiveCategories();
    fetchQuestions();
  }, [currentPage, pageSize, filterCategory, filterToggle]);

  const handleUploadPinecone = async () => {
    setUploading(true);
    try {
      await uploadQuestionPinecone();
      setMessage({
        type: "success",
        text: "Data successfully uploaded to Pinecone.",
      });
    } catch (error) {
      console.error("Failed to upload to Pinecone:", error);
      setMessage({ type: "error", text: "Failed to upload data to Pinecone." });
    } finally {
      setUploading(false);
      fetchQuestions();
    }
  };

  const fetchQuestions = async () => {
    setLoading(true);
    try {
      const response = await getQuestionsKnowledge(
        currentPage,
        pageSize,
        filterCategory,
        filterToggle
      );
      setQuestions(response.data);
      setRowsToCommit(response.rowsToCommit);
    } catch (error) {
      console.error("Failed to fetch questions:", error);
      setMessage({ type: "error", text: "Failed to load questions." });
    }
    setLoading(false);
  };

  const upsertQuestions = async (row: any) => {
    const payload = {
      id: row.id,
      categoryName: row.categoryName,
      description: row.description,
      question1: row.question1,
      quality1: row.quality1,
      notes1: row.notes1,
      question2: row.question2,
      quality2: row.quality2,
      notes2: row.notes2,
      question3: row.question3,
      quality3: row.quality3,
      notes3: row.notes3,
      deleted: row.deletedAt ? true : false,
    };
    try {
      await upsertQuestionsKnowledge(payload);
      setMessage({
        type: "success",
        text: "Operation completed successfully.",
      });
    } catch (error) {
      console.error("Failed to upsert row:", error);
      setMessage({ type: "error", text: "Failed to complete the operation." });
    }
  };

  const fetchActiveCategories = async () => {
    const categories = await CategoriesService.getActiveCategories();
    setActiveCategories(categories);
  };

  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
    setCurrentPage(1);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleCategoryFilterChange = (category: string) => {
    setFilterCategory(category === "Filter Category" ? "" : category);
    setCurrentPage(1);
  };

  const handleToggleFilterChange = () => {
    setFilterToggle((prev) => (prev === false ? true : !prev));
    setCurrentPage(1);
  };

  const handleNewRowInputChange = (field: string, value: any) => {
    if (newRow) {
      setNewRow({ ...newRow, [field]: value });
    }
  };

  // Maneja los cambios para filas existentes
  const handleExistingRowInputChange = (
    id: string,
    field: string,
    value: any
  ) => {
    const updatedData = questions.map((row) =>
      row.id === id ? { ...row, [field]: value } : row
    );
    setQuestions(updatedData);
  };

  const handleAddNewRow = () => {
    setNewRow({
      id: null,
      categoryName: "",
      description: "",
      question1: "",
      quality1: "",
      notes1: "",
      question2: "",
      quality2: "",
      notes2: "",
      question3: "",
      quality3: "",
      notes3: "",
      deleted: false,
    });
  };

  const handleSaveNewRow = async () => {
    if (newRow) {
      setLoading(true);
      try {
        await upsertQuestionsKnowledge(newRow);
        setMessage({ type: "success", text: "New row added successfully." });
        setNewRow(null);
        await fetchQuestions(); 
      } catch (error) {
        console.error("Failed to add new row:", error);
        setMessage({ type: "error", text: "Failed to add new row." });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSaveClick = async (row: any) => {
    setLoading(true);
    try {
      await upsertQuestions(row);
      setMessage({ type: "success", text: "Row saved successfully." });
    } catch (error) {
      console.error("Error saving row:", error);
      setMessage({ type: "error", text: "Failed to save row." });
    } finally {
      setEditRowId(null);
      setOriginalRowData(null);
      await fetchQuestions();
      setLoading(false);
    }
  };

  const handleCancelClick = () => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((row) =>
        row.id === originalRowData?.id ? originalRowData : row
      )
    );
    setEditRowId(null);
    setOriginalRowData(null);
  };

  const handleDeleteOrUndo = async (row: any) => {
    setLoading(true);
    try {
      const updatedData = {
        ...row,
        deletedAt: modal.action === "delete" ? new Date().toISOString() : null,
      };
      await upsertQuestions(updatedData);
      setMessage({
        type: "success",
        text:
          modal.action === "delete"
            ? "Row deleted successfully."
            : "Undo successful.",
      });
    } catch (error) {
      console.error("Error updating row:", error);
      setMessage({ type: "error", text: "Failed to update row." });
    } finally {
      setModal({ show: false, action: "delete", rowId: null });
      setEditRowId(null);
      setOriginalRowData(null);
      await fetchQuestions();
      setLoading(false);
    }
  };

  const isOutdated = (updatedAt: string, lastSyncTime: string) => {
    return new Date(updatedAt) > new Date(lastSyncTime) || !lastSyncTime;
  };

  return (
    <div className="relative">
      <p className="text-sm text-gray-600 mb-4">
        Rows to commit: <span className="font-semibold">{rowsToCommit}</span>
      </p>
      {loading && (
        <div className="absolute inset-0 bg-gray-100 bg-opacity-70 flex items-center justify-center z-50">
          <div className="flex items-center space-x-2">
            <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-blue-500"></div>
            <p className="text-gray-700">Processing...</p>
          </div>
        </div>
      )}
      {/* upload to pinecone button */}

      <div className="flex justify-between items-center mb-4">
        <button
          className="px-4 py-2 bg-green-500 text-white rounded"
          onClick={handleAddNewRow}
        >
          Add New
        </button>
      </div>

      {/* New Row Form */}
      {newRow && (
        <div className="bg-gray-100 p-4 rounded mb-4">
          <div className="grid grid-cols-4 gap-4">
            <select
              className="border rounded px-2 py-1"
              value={newRow.categoryName}
              onChange={(e) =>
                handleNewRowInputChange("categoryName", e.target.value)
              }
            >
              <option value="">Select Category</option>
              {activeCategories.map((category) => (
                <option key={category.id} value={category.name}>
                  {category.name}
                </option>
              ))}
            </select>
            <input
              type="text"
              className="border rounded px-2 py-1"
              placeholder="Description"
              value={newRow.description}
              onChange={(e) =>
                handleNewRowInputChange("description", e.target.value)
              }
            />
            {/* Add Question, Quality, and Notes Inputs */}
            {["question1", "question2", "question3"].map((field, index) => (
              <div key={field} className="col-span-4">
                <input
                  type="text"
                  className="border rounded px-2 py-1 w-full mb-2"
                  placeholder={`Question ${index + 1}`}
                  value={newRow[field]}
                  onChange={(e) =>
                    handleNewRowInputChange(field, e.target.value)
                  }
                />
                <input
                  type="text"
                  className="border rounded px-2 py-1 w-full mb-2"
                  placeholder={`Quality ${index + 1}`}
                  value={newRow[`quality${index + 1}`]}
                  onChange={(e) =>
                    handleNewRowInputChange(
                      `quality${index + 1}`,
                      e.target.value
                    )
                  }
                />
                <input
                  type="text"
                  className="border rounded px-2 py-1 w-full"
                  placeholder={`Notes ${index + 1}`}
                  value={newRow[`notes${index + 1}`]}
                  onChange={(e) =>
                    handleNewRowInputChange(`notes${index + 1}`, e.target.value)
                  }
                />
              </div>
            ))}
          </div>
          <div className="flex justify-end space-x-2 mt-4">
            <button
              className="px-4 py-2 bg-green-500 text-white rounded"
              onClick={handleSaveNewRow}
            >
              Save
            </button>
            <button
              className="px-4 py-2 bg-gray-500 text-white rounded"
              onClick={() => setNewRow(null)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {message && (
        <div
          className={`fixed top-4 right-4 px-4 py-2 rounded ${
            message.type === "success"
              ? "bg-green-500 text-white"
              : "bg-red-500 text-white"
          }`}
        >
          {message.text}
        </div>
      )}

      {/* Filtro de categorías y toggle */}
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center space-x-4">
          {/* Filtro por categoría */}
          <select
            className="border rounded px-2 py-1"
            value={filterCategory || "Filter Category"} // Mostrar "Filter Category" si está vacío
            onChange={(e) => handleCategoryFilterChange(e.target.value)}
          >
            <option value="Filter Category">Filter Category</option>
            {activeCategories.map((category) => (
              <option key={category.id} value={category.name}>
                {category.name}
              </option>
            ))}
          </select>

          {/* Filtro por toggle */}
          <div className="flex items-center">
            <span className="mr-2">Pending Filter:</span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={filterToggle || false}
                onChange={handleToggleFilterChange}
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-500 rounded-full peer peer-checked:bg-blue-500 peer-checked:after:translate-x-5 peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all"></div>
            </label>
          </div>
        </div>

        <button
          className={`px-4 py-2 rounded ${
            uploading
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-blue-500 text-white"
          }`}
          onClick={handleUploadPinecone}
          disabled={uploading}
        >
          {uploading ? "Uploading..." : "Upload to Pinecone"}
        </button>
      </div>

      <table className="min-w-full table-auto border-collapse border border-gray-200">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-4 py-2 border text-left min-w-[150px]">
              Category
            </th>
            <th className="px-4 py-2 border text-left min-w-[200px]">
              Description
            </th>
            <th className="px-4 py-2 border text-left min-w-[150px]">
              Deleted
            </th>
            <th className="px-4 py-2 border text-center min-w-[100px]">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {questions.map((row) => (
            <React.Fragment key={row.id}>
              {/* Main Row */}
              <tr
                className={`${
                  isOutdated(row.updatedAt, row.lastSyncTime)
                    ? "bg-yellow-100"
                    : "bg-white"
                }`}
              >
                <td className="px-4 py-2 border">
                  <select
                    className="border rounded px-2 py-1 w-full"
                    value={row.categoryName}
                    onChange={(e) =>
                      handleExistingRowInputChange(
                        row.id,
                        "categoryName",
                        e.target.value
                      )
                    }
                    disabled={editRowId !== row.id}
                  >
                    {activeCategories.map((category) => (
                      <option key={category.id} value={category.name}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </td>
                <td className="px-4 py-2 border">
                  <input
                    type="text"
                    className="border rounded px-2 py-1 w-full"
                    value={row.description}
                    onChange={(e) =>
                      handleExistingRowInputChange(
                        row.id,
                        "description",
                        e.target.value
                      )
                    }
                    disabled={editRowId !== row.id}
                  />
                </td>
                <td className="px-4 py-2 border text-red-500">
                  {row.deletedAt ? timeAgo(row.deletedAt) : "Not deleted"}
                </td>
                <td className="px-4 py-2 border text-center">
                  {editRowId === row.id ? (
                    <div className="flex space-x-2 justify-center">
                      <button
                        className="px-2 py-1 bg-green-500 text-white rounded"
                        onClick={() => handleSaveClick(row)}
                      >
                        Save
                      </button>
                      <button
                        className="px-2 py-1 bg-red-500 text-white rounded"
                        onClick={handleCancelClick}
                      >
                        Cancel
                      </button>
                      <button
                        className={`px-2 py-1 ${
                          row.deletedAt ? "bg-gray-500" : "bg-red-500"
                        } text-white rounded`}
                        onClick={() =>
                          setModal({
                            show: true,
                            action: row.deletedAt ? "undo" : "delete",
                            rowId: row.id,
                          })
                        }
                      >
                        {row.deletedAt ? "Undo" : "Delete"}
                      </button>
                    </div>
                  ) : (
                    <button
                      className="px-2 py-1 bg-blue-500 text-white rounded"
                      onClick={() => {
                        setOriginalRowData({ ...row });
                        setEditRowId(row.id);
                      }}
                    >
                      Edit
                    </button>
                  )}
                </td>
              </tr>
              {/* Secondary Row */}
              <tr className="bg-gray-50">
                <td colSpan={4} className="px-4 py-2 border">
                  <div className="text-sm text-gray-700 space-y-2">
                    {["question1", "question2", "question3"].map(
                      (field, index) => (
                        <div key={field}>
                          <div className="flex items-center space-x-4">
                            <strong className="min-w-[120px]">{`Question ${
                              index + 1
                            }:`}</strong>
                            {editRowId === row.id ? (
                              <>
                                <input
                                  type="text"
                                  className="border rounded px-2 py-1 w-[70%] text-blue-600"
                                  value={row[field]}
                                  onChange={(e) =>
                                    handleExistingRowInputChange(
                                      row.id,
                                      field,
                                      e.target.value
                                    )
                                  }
                                />
                                <input
                                  type="text"
                                  className="border rounded px-2 py-1 w-20 text-gray-600"
                                  value={row[`quality${index + 1}`]}
                                  onChange={(e) =>
                                    handleExistingRowInputChange(
                                      row.id,
                                      `quality${index + 1}`,
                                      e.target.value
                                    )
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <span className="text-blue-600">
                                  {row[field]}
                                </span>
                                <em className="text-gray-600">
                                  ({row[`quality${index + 1}`]})
                                </em>
                              </>
                            )}
                          </div>
                          <div className="mt-1">
                            <strong>{`Note ${index + 1}:`}</strong>{" "}
                            {editRowId === row.id ? (
                              <input
                                type="text"
                                className="border rounded px-2 py-1 w-full"
                                value={row[`notes${index + 1}`]}
                                onChange={(e) =>
                                  handleExistingRowInputChange(
                                    row.id,
                                    `notes${index + 1}`,
                                    e.target.value
                                  )
                                }
                              />
                            ) : (
                              <span>{row[`notes${index + 1}`]}</span>
                            )}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="flex justify-between items-center mt-4">
        <div>
          <label htmlFor="pageSize" className="mr-2">
            Rows per page:
          </label>
          <select
            id="pageSize"
            className="border rounded px-2 py-1"
            value={pageSize}
            onChange={(e) => handlePageSizeChange(Number(e.target.value))}
          >
            {[10, 15, 20, 25, 50].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        <div className="flex items-center space-x-2">
          <button
            className={`px-3 py-1 rounded ${
              currentPage === 1
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-blue-500 text-white"
            }`}
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span>Page {currentPage}</span>
          <button
            className={`px-3 py-1 rounded ${
              questions.length < pageSize
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-blue-500 text-white"
            }`}
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={questions.length < pageSize}
          >
            Next
          </button>
        </div>
      </div>

      {/* Modal */}
      {modal.show && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded p-4 w-1/3">
            <h2 className="text-lg font-bold mb-4">Are you sure?</h2>
            <div className="flex justify-end space-x-2">
              <button
                className="px-4 py-2 bg-red-500 text-white rounded"
                onClick={() =>
                  handleDeleteOrUndo(
                    questions.find((q) => q.id === modal.rowId)
                  )
                }
              >
                Confirm
              </button>
              <button
                className="px-4 py-2 bg-gray-300 rounded"
                onClick={() =>
                  setModal({ show: false, action: "delete", rowId: null })
                }
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionKnowledgeBase;

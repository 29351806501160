
import { Route } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import { routeNames } from "./routeNames";
import { AssistantDemo } from "../pages/ai/AiIndexPage/AiIndexPage";
import { PricePrediction } from "../pages/ai";
import { KnowledgeQuestionBase } from "../pages/ai/KnowledgeQuestionBase";
import { RelatedKnowledgeBase } from "../pages/ai/RelatedBase";
import { PriceKnowledgeBase } from "../pages/ai/PriceKnowledgeBase";
export const AiRoutes = (
  <>
    <Route
      path={routeNames["ai.assistant"]}
      element={
        <ProtectedRoute>
          <AssistantDemo
            title="Assistant Demo"
          />
        </ProtectedRoute>
      }
    />
    <Route
      path={routeNames["ai.prices"]}
      element={
        <ProtectedRoute>
        <PricePrediction/>
        </ProtectedRoute>
      }
    />
    <Route
      path={routeNames["ai.knowledge"]}
      element={
        <ProtectedRoute>
        <KnowledgeQuestionBase/>
        </ProtectedRoute>
      }
    />
    <Route
      path={routeNames["ai.related"]}
      element={
        <ProtectedRoute>
        <RelatedKnowledgeBase/>
        </ProtectedRoute>
      }
    />
    <Route
      path={routeNames["ai.priceKnowledge"]}
      element={
        <ProtectedRoute>
        <PriceKnowledgeBase/>
        </ProtectedRoute>
      }
    />
  </>
);

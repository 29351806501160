import RelatedBase from "../../../components/ai/Demos/RelatedBaseTest";


export const RelatedKnowledgeBase = () => {
  return (
    <div>
      <div className="flex-1 p-6 bg-white rounded-lg shadow-md">
          <h3 className="text-xl font-semibold mb-4">Related Knowledge Base</h3>
          <RelatedBase />
        </div>
    </div>
  );
}
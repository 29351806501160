import React, { useState } from "react";
import { generateQuestions, generateRequestEnhanced } from "../../../services"; // Adjust the import path if needed
import { AIQuestionData, RequestEnhancedPayload, RequestEnhancedResponse } from "../../../types"; // Adjust the import path if needed

const QuestionGeneratorDemo: React.FC = () => {
  const [questionData, setQuestionData] = useState<AIQuestionData | null>(null); // Holds the generated questions data
  const [inputText, setInputText] = useState<string>(""); // Holds the user's input
  const [loading, setLoading] = useState<boolean>(false); // Loading state
  const [error, setError] = useState<string | null>(null); // Error handling
  const [answers, setAnswers] = useState<{ [key: string]: string }>({}); // Holds the selected answers
  const [enhancedResponse, setEnhancedResponse] = useState<RequestEnhancedResponse | null>(null); // Holds the enhanced response
  const [inputError, setInputError] = useState<string | null>(null); // Error for input validation
  const [answerError, setAnswerError] = useState<string | null>(null); // Error for answer validation
  const [locale, setLocale] = useState<string>("en"); // en or es

  const handleGenerateQuestion = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setInputError(null);
    setEnhancedResponse(null);

    if (inputText.trim().length < 8) {
      setInputError("The request should be at least 8 characters long.");
      return;
    }

    setLoading(true); 
    setError(null);

    try {
      const response = await generateQuestions({
        question: inputText,
        locale: locale 
      }); 
      
      setQuestionData(response);
      setAnswers({});
    } catch (err) {
      console.error("Error generating question:", err);
      setError("Something went wrong. Please try again.");
    } finally {
      setLoading(false); 
    }
  };

  const handleAnswerSelect = (questionKey: string, answer: string) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionKey]: answer,
    }));
    setAnswerError(null); 
  };

  const handleSubmitAnswers = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); 
    setAnswerError(null); 

    if (questionData === null) return; // Ensure that questionData is not null

    // Ensure all questions have been answered
    if (questionData.questions.some(q => answers[q.question] === undefined)) {
      setAnswerError("You must respond to all the questions to generate the enhanced request.");
      return;
    }

    const formattedAnswers = questionData.questions.map(question => ({
      question: question.question,
      answer: answers[question.question] || "" // Ensure no undefined values
    }));

    const payload: RequestEnhancedPayload = {
      initialRequest: inputText,  // Include the initial request the user made
      responseDetails: formattedAnswers,  // Include the formatted questions and answers
      locale: locale  
    };

    console.log("Payload to be sent:", payload); // Log the payload to be sent to the API

    setLoading(true); // Start loading

    try {
      const response: RequestEnhancedResponse = await generateRequestEnhanced(payload); // Send the payload to the enhanced request API
      console.log("Enhanced Request Response:", response); // Log the response from the enhanced request API
      setEnhancedResponse(response); // Store the response to display it
    } catch (err) {
      console.error("Error in generateRequestEnhanced:", err);
      setError("Something went wrong with the enhanced request. Please try again.");
    } finally {
      setLoading(false); // End loading
    }
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4 text-center">
        Tell Us About the Problem
      </h2>

      <form onSubmit={handleGenerateQuestion}>
        <textarea
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          placeholder="What's the issue you need help with?"
          className={`w-full p-3 border rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500 ${inputError ? 'border-red-500' : ''}`}
        />
        {inputError && <p className="text-red-500 mt-1">{inputError}</p>}

        {/* Dropdown to select locale */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Select Locale</label>
          <select
            value={locale}
            onChange={(e) => setLocale(e.target.value)}
            className="block w-full bg-white border border-gray-400 rounded py-2 px-3 text-gray-700 focus:outline-none"
          >
            <option value="en">English</option>
            <option value="es">Spanish</option>
          </select>
        </div>

        <button
          type="submit"
          className={`w-full bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 transition ${
            loading ? 'cursor-not-allowed' : ''
          }`}
          disabled={loading}
        >
          {loading ? "Finding Questions..." : "Next"}
        </button>
      </form>

      {error && <p className="text-red-500 mt-4">{error}</p>}

      {questionData && questionData.isIllegal === true? (
        <div className="mt-6">
          <h3 className="text-xl font-semibold mb-2">Assistant:</h3>
          <p className="bg-gray-100 p-4 rounded-lg">The request is illegal</p>
        </div>
      ) : (
        questionData && (
          <form onSubmit={handleSubmitAnswers} className="mt-6">
            <h3 className="text-xl font-semibold mb-2">We just need a little more info:</h3>

            {questionData.questions.map((question, index) => (
              <div key={index} className="bg-gray-100 p-4 mb-4 rounded-lg">
                <p className="font-semibold">{question.question}</p>
                <div className="mt-2">
                  {question.answers.map((answer, answerIndex) => (
                    <button
                      key={answerIndex}
                      type="button"
                      className={`mr-2 mb-2 py-2 px-4 rounded-lg focus:outline-none ${
                        answers[question.question] === answer
                          ? "bg-blue-500 text-white"
                          : "bg-gray-200 text-black"
                      }`}
                      onClick={() => handleAnswerSelect(question.question, answer)}
                    >
                      {answer}
                    </button>
                  ))}
                </div>
              </div>
            ))}

            {answerError && <p className="text-red-500 mt-1">{answerError}</p>}
          
            {questionData.isUnrelated && (
              <p className="text-red-500 mt-4">The request is unrelated to the dingdoor services.</p>
            )}
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mt-4"
              disabled={loading} // Disable if loading
            >
              {loading ? "Submitting..." : "Send Your Answers"}
            </button>
          </form>
        )
      )}

      {/* Display the summary and inferred category if available */}
      {enhancedResponse && (
        <div className="mt-6 bg-gray-100 p-4 rounded-lg">
          <h3 className="text-xl font-semibold mb-2">Request Enhanced</h3>
          <p className="text-lg"><span className="font-semibold">Summary:</span> {enhancedResponse.summary}</p>
          <p className="text-lg"><span className="font-semibold">Category:</span> {enhancedResponse.inferredCategory.categoryName}</p>
        </div>
      )}
    </div>
  );
};

export default QuestionGeneratorDemo;

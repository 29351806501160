import KnowledgeBasesDemo from "../../../components/ai/Demos/KnowledgeBaseTest";


export const KnowledgeQuestionBase = () => {
  return (
    <div>
      <div className="flex-1 p-6 bg-white rounded-lg shadow-md">
          <h3 className="text-xl font-semibold mb-4">Questions Knowledge Base</h3>
          <KnowledgeBasesDemo />
        </div>
    </div>
  );
}